/*
import 'theme-change';

document.addEventListener('DOMContentLoaded', () => {
  setInitialTheme();
  setupThemeButtons();
});

// Function to set the theme based on system preference
const setInitialTheme = () => {
  // Check if 'theme' key exists in localStorage
  const savedTheme = localStorage.getItem('theme');
  const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';

  // If 'theme' key is not present, set it to 'system'
  if (savedTheme === null) {
    localStorage.setItem('theme', 'system');
    document.documentElement.setAttribute('data-theme', systemTheme);
    document.documentElement.classList.add(systemTheme);
  } else {
    // Use the saved theme or system theme
    const theme = savedTheme === 'system' ? systemTheme : savedTheme || systemTheme;
    document.documentElement.setAttribute('data-theme', theme);
    document.documentElement.classList.add(theme);
  }
};

// Set initial theme based on system preference or saved setting
// setInitialTheme();

// Listen for changes in the system theme preference and update the theme accordingly
window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (event) => {
  if (localStorage.getItem('theme') === 'system') {
    const newTheme = event.matches ? 'dark' : 'light';
    document.documentElement.classList.replace(
      document.documentElement.classList.contains('dark') ? 'dark' : 'light',
      newTheme
    );
    document.documentElement.setAttribute('data-theme', newTheme);
  }
});

// Function to handle theme change on button click
const setupThemeButtons = () => {
  document.querySelectorAll('[data-set-theme]').forEach(button => {
    button.addEventListener('click', () => {
      const theme = button.getAttribute('data-set-theme');
      if (theme === 'system') {
        // Set the theme to the current system theme
        localStorage.setItem('theme', 'system');
        const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
        document.documentElement.classList.replace(
          document.documentElement.classList.contains('dark') ? 'dark' : 'light',
          systemTheme
        );
        document.documentElement.setAttribute('data-theme', systemTheme);
      } else {
        // Save the selected theme and apply it
        localStorage.setItem('theme', theme);
        document.documentElement.classList.replace(
          document.documentElement.classList.contains('dark') ? 'dark' : 'light',
          theme
        );
        document.documentElement.setAttribute('data-theme', theme);
      }
    });
  });
};

// Initialize theme buttons
// setupThemeButtons();
*/